import React, { FC, useRef } from 'react';

import cn from 'classnames';
import { useAppSelector } from 'src/hooks/redux';

interface TextAreaProps {
  value?: string;
  defaultValue?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  classNames?: string;
}

export const TextArea: FC<TextAreaProps> = ({
  value,
  defaultValue,
  onChange,
  placeholder,
  classNames,
}) => {
  const {
    common: { settings },
  } = useAppSelector(state => state);
  const ref = useRef<HTMLTextAreaElement>(null);

  const handleChange = e => {
    onChange(e.target.value);
  };

  const isWhite = ['#FF0092', '#CC8558'].includes(settings.textColor);

  return (
    <textarea
      maxLength={200}
      ref={ref}
      rows={2}
      className={cn(
        {
          'bg-white': Boolean(ref?.current?.value),
          'bg-transparent': !Boolean(ref?.current?.value),
          'placeholder:text-white': isWhite,
          'placeholder:text-black': !isWhite,
        },
        'p-4 w-full border rounded-xl text-sm',
        'font-gilroy-400 focus:bg-white resize-none',
        classNames,
      )}
      style={{ borderColor: settings.textColor, color: settings.textColor }}
      defaultValue={defaultValue}
      value={value}
      onChange={handleChange}
      placeholder={placeholder || ''}
    />
  );
};
