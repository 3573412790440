import { CommonProps } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchSettings } from './actions';
import { ISetting } from 'src/models/ISetting';
import {
  defaultButtonsColor,
  defaultColor,
  defaultDiscountColor,
  defaultTextColor,
} from 'src/config/data';

const initialState: CommonProps = {
  settings: {
    image: '',
    color: defaultColor,
    buttonsColor: defaultButtonsColor,
    textColor: defaultTextColor,
    discountColor: defaultDiscountColor,
  },
  isLoadingSettings: true,
  shopId: '',
  error: '',
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setShopId: (state, action: PayloadAction<string>) => {
      state.shopId = action.payload;
    },
  },
  extraReducers: {
    [fetchSettings.pending.type]: state => {
      state.isLoadingSettings = true;
    },
    [fetchSettings.fulfilled.type]: (state, action: PayloadAction<ISetting>) => {
      state.isLoadingSettings = false;
      state.error = '';
      state.settings = {
        ...action.payload,
        buttonsColor: action.payload.buttonsColor.includes('linear-gradient')
          ? action.payload.buttonsColor.replace('#', '')
          : action.payload.buttonsColor,
      };
    },
    [fetchSettings.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingSettings = false;
      state.error = action.payload;
    },
  },
});

export const { setShopId } = commonSlice.actions;

const reducer = commonSlice.reducer;
export default reducer;
