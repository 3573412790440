import { FC } from 'react';
import { ICategory } from 'src/models/ICategory';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { Link } from 'react-router-dom';
import { setSubGroupsFilter } from 'src/store/filter';

interface GroupCard {
  group: ICategory;
}

export const GroupCard: FC<GroupCard> = ({ group }) => {
  const {
    common: {
      settings: { buttonsColor, color },
    },
  } = useAppSelector(state => state);
  const dispatch = useAppDispatch();

  const handleResetSelectedGroups = () => {
    dispatch(setSubGroupsFilter([]));
  };

  return (
    <Link
      onClick={handleResetSelectedGroups}
      to={`/catalog/${group.id}${window.location.search}`}
      className="w-full rounded-xl overflow-hidden"
    >
      <div className="w-full square bg-white">
        <img className="w-full h-full object-cover" src={group.image} alt="" />
      </div>
      <div
        className="w-full px-5 py-3 truncate font-gilroy-600 text-lg"
        style={{ background: buttonsColor, color }}
      >
        {group.name}
      </div>
    </Link>
  );
};
