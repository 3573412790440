import { FC } from 'react';
import { useAppSelector } from 'src/hooks/redux';
import { useNavigate } from 'react-router-dom';

import cn from 'classnames';

interface ButtonProps {
  text: string;
  variant: 'fill' | 'outline';
  onClick?: () => void;
  href?: string;
  classNames?: string;
}

export const Button: FC<ButtonProps> = ({ text, variant, onClick, href, classNames }) => {
  const { settings } = useAppSelector(state => state.common);
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) onClick();

    if (href) navigate(href);
  };

  return (
    <button
      onClick={handleClick}
      className={cn(
        'rounded-full flex items-center justify-center border font-gilroy-600',
        classNames,
      )}
      style={{
        background: variant === 'fill' ? settings.buttonsColor : 'transparent',
        borderColor: settings.buttonsColor,
        color:
          variant === 'fill'
            ? settings.color
            : settings.buttonsColor.includes('gradient')
            ? '#FFF356'
            : settings.buttonsColor,
      }}
    >
      {text}
    </button>
  );
};
