import { FC, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { setSubGroupsFilter } from 'src/store/filter';
import { SubGroupType } from 'src/store/filter/types';

import cn from 'classnames';

export const SubGroupsList: FC = () => {
  const dispatch = useAppDispatch();
  const {
    common: { settings },
    categories: { subGroups },
    filters: { subGroupsFilter },
  } = useAppSelector(state => state);

  const sortedList = useMemo(() => {
    if (!subGroupsFilter.length) {
      return subGroups;
    }

    const selectedIds = subGroupsFilter.map(el => el.id);
    const withoutActive = subGroups.filter(el => !selectedIds.includes(el.id));

    return [...subGroupsFilter, ...withoutActive];
  }, [subGroups, subGroupsFilter]);

  const handleSelect = (subGroup: SubGroupType, isActive: boolean) => {
    const newGroups = isActive
      ? subGroupsFilter.filter(el => el.id !== subGroup.id)
      : [...subGroupsFilter, subGroup];

    dispatch(setSubGroupsFilter(newGroups));
  };

  const activeColor = settings.buttonsColor.includes('gradient')
    ? '#FFF356'
    : settings.buttonsColor;

  return (
    <div className="px-4 overflow-auto scrollbar-hide flex gap-2.5">
      {sortedList.map(subGroup => {
        const isActive = !!subGroupsFilter.find(el => el.id === subGroup.id);

        return (
          <div
            onClick={() => handleSelect(subGroup, isActive)}
            key={`subgroup-list-${subGroup.id}`}
            className={cn(
              { 'cursor-pointer': !isActive },
              'px-5 py-1.5 rounded-full font-gilroy-400 text-sm border min-w-[8rem] max-w-[8rem]',
              'text-center truncate',
            )}
            style={{
              color: isActive ? settings.color : activeColor,
              background: isActive ? settings.buttonsColor : 'transparent',
              borderColor: activeColor,
            }}
          >
            {subGroup.name}
          </div>
        );
      })}
    </div>
  );
};
