export const defaultColor = '#BFEEF4';
export const defaultButtonsColor = '#EA58D5';
export const defaultTextColor = '#FFFFFF';
export const defaultDiscountColor = '#000000';

export const orderStatuses = { 1: 'Новый', 2: 'В работе', 3: 'Получен', 4: 'Отменён' };

export const orderTypesData = {
  current: 'Текущие',
  archive: 'Архив',
  canceled: 'Отмененные',
};

export const monthsNames: string[] = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];
