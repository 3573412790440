import { FC, useMemo } from 'react';
import { IProduct } from 'src/models/IProduct';
import { numberWithSpaces, stringToNumber } from 'src/utils';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { setProductToBasket } from 'src/store/basket/actions';
import { Link } from 'react-router-dom';

import { ReactComponent as PlusIcon } from 'src/assets/icons/kit/plus.svg';
import { ReactComponent as MinusIcon } from 'src/assets/icons/kit/minus.svg';
import { ReactComponent as CloseIcon } from 'src/assets/icons/kit/close.svg';

import cn from 'classnames';

interface SmallProductCardProps {
  product: IProduct;
  classNames?: string;
}

export const SmallProductCard: FC<SmallProductCardProps> = ({ product, classNames }) => {
  const dispatch = useAppDispatch();
  const {
    basket: { basketProducts },
    products: { products },
    shops: { shop },
    common: { settings },
  } = useAppSelector(state => state);

  const amount = useMemo(() => {
    if (!basketProducts?.[shop.typeId]) {
      return product.amount;
    }

    const productFromBasket = basketProducts[shop.typeId].find(item => item.id === product.id);

    if (productFromBasket) {
      return productFromBasket.amount;
    }

    return product.amount;
  }, [basketProducts, product, products, setProductToBasket, setProductToBasket, shop]);

  const handleSetProductToBasket = (newProduct: IProduct) => {
    dispatch(setProductToBasket({ products: [newProduct], shop }));
  };

  const handleAddToBasket = e => {
    e.preventDefault();
    const newProduct = { ...product, amount: amount + 1 };
    handleSetProductToBasket(newProduct);
  };

  const handleRemoveFromBasket = e => {
    e.preventDefault();
    const newProduct = { ...product, amount: amount - 1 };
    handleSetProductToBasket(newProduct);
  };

  const handleClearFromBasket = e => {
    e.preventDefault();
    const newProduct = { ...product, amount: 0 };
    handleSetProductToBasket(newProduct);
  };

  return (
    <Link
      to={`/product/${product.id}${window.location.search}`}
      className={cn('w-full bg-white flex p-3 gap-3', classNames)}
    >
      <div className="w-16 h-16 min-w-[4rem] rounded-md overflow-hidden">
        <img className="w-full h-full object-contain" src={product.image} alt="" />
      </div>
      <div className="" style={{ width: 'calc(100% - 104px )' }}>
        <div className="font-gilroy-600 text-xs mb-0.5 w-full truncate">{product.name}</div>
        <div className="line-clamp-3 font-gilroy-500 text-extra-xs text-grey-100 w-full min-h-[0.75rem]">
          {product.description}
        </div>
        <div className="flex justify-between w-full">
          <div>
            <div
              className="text-pink-100 font-gilroy-600 text-lg-1 mb-0.5"
              style={{ color: settings.discountColor }}
            >
              {`${numberWithSpaces(product.newPrice)} ${product?.currency || '₽'}`}
            </div>
            <div className="text-grey-300 h-2.5 text-extra-xs-1 font-gilroy-600 line-through mb-2.5">
              {+product.discount > 0 ||
              +stringToNumber(product.oldPrice) > +stringToNumber(product.newPrice)
                ? `${numberWithSpaces(product.oldPrice)} ${product?.currency || '₽'}`
                : null}
            </div>
          </div>

          <div className="rounded-xl flex h-8" style={{ backgroundColor: settings.color }}>
            <div className="h-full items-center flex pr-2 pl-4" onClick={handleRemoveFromBasket}>
              <MinusIcon className="w-2 h-2" style={{ fill: settings.textColor }} />
            </div>
            <div
              className="h-full flex items-center text-white font-gilroy-600 text-xs px-1"
              style={{ color: settings.textColor }}
            >
              {product.amount}
            </div>
            <div className="h-full items-center flex pl-2 pr-4" onClick={handleAddToBasket}>
              <PlusIcon className="w-2 h-2" style={{ fill: settings.textColor }} />
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-4 h-4 min-w-[1rem]" onClick={handleClearFromBasket}>
        <CloseIcon className="cursor-pointer w-3 h-3 fill-grey-400 ml-auto" />
      </div>
    </Link>
  );
};
