import { FC, useMemo } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { numberWithSpaces, stringToNumber } from 'src/utils';
import { ProductCounter } from 'src/components/ProductCounter';
import { Button } from 'src/components/Button';
import { ImagesCarousel } from 'src/components/ImagesCarousel';
import { Stat } from 'src/components/Stat';
import { useAppSelector } from 'src/hooks/redux';

import { ReactComponent as ArrowIcon } from 'src/assets/icons/kit/arrow.svg';

export const ProductPage: FC = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const {
    products: { products },
    common: {
      settings: { color, textColor, discountColor, buttonsColor },
    },
  } = useAppSelector(state => state);

  const product = useMemo(
    () => products?.find(prod => prod?.id?.toString() === productId),
    [productId, products],
  );

  const handleBack = () => {
    navigate(-1);
  };

  if (!product) {
    return null;
  }

  const imagesList = [product.image, ...(product?.carouselImages || [])];

  return (
    <div className="flex flex-col h-full">
      <div className="w-full h-72 relative">
        <ImagesCarousel imagesList={imagesList} classNames="w-full h-full" />

        <div
          className={
            'absolute top-4 left-4 bg-white rounded-full w-12 h-12 flex items-center ' +
            'justify-center cursor-pointer'
          }
          onClick={handleBack}
        >
          <ArrowIcon className="w-4 h-4" style={{ fill: buttonsColor }} />
        </div>
      </div>

      <div className="p-4 flex flex-col gap-1.5">
        <div className="font-gilroy-600 text-xl" style={{ color: textColor }}>
          {product.name}
        </div>
        {product.description ? (
          <div className="text-grey-100 font-gilroy-500 text-xs">{product.description}</div>
        ) : null}
        <div className="flex gap-2.5 items-center">
          {+product.discount > 0 ||
          +stringToNumber(product.oldPrice) > +stringToNumber(product.newPrice) ? (
            <div className="text-grey-300 text-xs font-gilroy-600 line-through">
              {`${numberWithSpaces(product.oldPrice)} ${product?.currency || '₽'}`}
            </div>
          ) : null}
          <div className="text-pink-100 font-gilroy-600 text-lg" style={{ color: discountColor }}>
            {`${numberWithSpaces(product.newPrice)} ${product?.currency || '₽'}`}
          </div>
          {product.discount && product.discount.toString() !== '0' ? (
            <div
              className={
                'px-1 py-0.5 bg-pink-100 rounded font-gilroy-700 text-extraSmall text-white'
              }
              style={{ background: discountColor, color }}
            >
              -{product.discount}%
            </div>
          ) : null}
        </div>
      </div>

      {product?.parameters?.length ? (
        <div className="px-4 flex flex-col gap-2.5">
          {product.parameters.map((el, i) => (
            <Stat stat={el} key={`product-stat-${i}`} />
          ))}
        </div>
      ) : null}

      <div className="mt-auto p-4">
        <ProductCounter big product={product} />
        <Button
          href={`/${window.location.search}`}
          text="В каталог"
          variant="outline"
          classNames="w-full h-12 mt-3"
        />
      </div>
    </div>
  );
};
