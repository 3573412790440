// @ts-ignore
const tg = window?.['Telegram']?.WebApp;

export const useTelegram = () => {
  const onClose = () => {
    tg.close();
  };

  const onExpand = () => {
    tg.expand();
  };

  const disableClose = () => {
    tg.enableClosingConfirmation();
  };

  const onOpenLink = (url: string) => {
    tg.openLink(url);
  };

  return {
    onClose,
    onExpand,
    disableClose,
    onOpenLink,
    queryId: tg?.initDataUnsafe?.query_id,
    chatId: tg?.initDataUnsafe?.user?.id,
    // chatId: 5940976131,
  };
};
