import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowIcon } from 'src/assets/icons/kit/arrow.svg';
import { useAppSelector } from 'src/hooks/redux';

interface BackButtonProps {
  href?: string;
  onClick?: () => void;
  text?: string;
}

export const BackButton: FC<BackButtonProps> = ({ href, onClick, text }) => {
  const {
    settings: { textColor },
  } = useAppSelector(state => state.common);
  const navigate = useNavigate();

  const handleBack = () => {
    onClick && onClick();
    navigate(-1);
  };

  const node = (
    <>
      <ArrowIcon className="w-4 h-4 min-w-[1rem]" style={{ fill: textColor }} />
      <div className="w-[70%] truncate text-lg font-gilroy-600" style={{ color: textColor }}>
        {text || ''}
      </div>
    </>
  );

  return href ? (
    <Link
      to={`${href}${window.location.search}`}
      onClick={onClick}
      className="flex gap-4 items-center w-full cursor-pointer"
    >
      {node}
    </Link>
  ) : (
    <div onClick={handleBack} className="flex gap-4 items-center w-full cursor-pointer">
      {node}
    </div>
  );
};
